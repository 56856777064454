var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ds-table-alt",style:({ width: _vm.width ? _vm.width : '', gap: _vm.gap ? _vm.gap + 'px' : '' })},[(_vm.showHeader)?_c('div',{staticClass:"table-header",style:({ 'grid-template-columns': _setup.displayTableHeader.grid })},[_vm._l((_vm.columns),function(cell,cellIindex){return _c('div',{key:cellIindex,staticClass:"header-cell"},[(cell.iconHeader)?_c('i',{class:`ti ti-${cell.iconHeader}`}):_vm._e(),(cell.header)?[_c('span',{staticClass:"header-cell-text"},[_vm._v(_vm._s(cell.header))])]:_vm._e()],2)}),(!!_vm.globalActions?.headerActions)?_c('div',{staticClass:"global-column-action",style:({ width: _vm.globalActions.width ?? '' })},_vm._l((_vm.globalActions.headerActions),function(action){return (action.action)?_c(action.component,_vm._b({tag:"component",on:{"click":function($event){return action.action()}}},'component',action.props,false)):_vm._e()}),1):_vm._e()],2):_vm._e(),_c('div',{staticClass:"rows-container",style:({ gap: _vm.gap ? _vm.gap + 'px' : '' })},_vm._l((_vm.rows),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"table-row",class:[{ rowHover: _vm.rowHover }],style:({ 'grid-template-columns': _setup.displayTableHeader.grid })},[_vm._l((_vm.columns),function(cell,cellIindex){return (!row?.editMode)?_c('div',{key:cellIindex,staticClass:"table-cell"},[(row.content[cell.field]?.text)?_c('span',{class:[
                        'cell-text',
                        {
                            leading: row.content[cell.field].text?.leading,
                            passive: row.content[cell.field].text?.passive,
                            none: row.content[cell.field].text?.none,
                        },
                    ]},[_vm._v(" "+_vm._s(row.content[cell.field].text?.value)+" ")]):(row.content[cell.field]?.customElement)?_c(row.content[cell.field].customElement?.component,_vm._b({tag:"component",on:{"click":function($event){return _setup.handleComponentClick(row, cell, rowIndex)}}},'component',row.content[cell.field].customElement?.props,false)):_c('div')],1):_vm._e()}),((_vm.globalActions?.rowActions && !row?.editMode) || false)?_c('div',{staticClass:"global-row-action",style:({ width: _vm.globalActions.width ?? '' })},_vm._l((_vm.globalActions.rowActions),function(action){return (action.action)?_c('div',{on:{"click":function($event){return action.action(rowIndex)}}},[_c(action.component,_vm._b({tag:"component"},'component',action.props,false))],1):_vm._e()}),0):_vm._e(),(row.editMode)?_c('div',{staticClass:"edit-slot"},[_vm._t("edit",null,{"row":row,"rowIndex":rowIndex})],2):_vm._e()],2)}),0),(_setup.instance?.$slots['bottom-slot'])?_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-cell"},[_vm._t("bottom-slot")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }