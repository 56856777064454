import moment from "moment-timezone";
import { ref } from "vue";
import store from "@/store";
import { AcitveProposalCompany, ActiveProposalStatus, Timestamp } from "@/types";

interface useUpdateActiveProposalStates {
    loading: boolean;
    response: any;
}

interface ReafuseReason {
    reason: any;
    details: string;
}

export const useUpdateActiveProposal = (
    onSucceed?: () => void,
    onError?: (error: unknown) => void
): [
    (
        id: string,
        companies: AcitveProposalCompany[],
        command: ActiveProposalStatus,
        reason?: ReafuseReason | null,
        date?: string | null,
        missionId?: string | null
    ) => Promise<void>,
    useUpdateActiveProposalStates
] => {
    const loading = ref(false);
    const response = ref<any>(null);

    const updateActivePropposal = async (
        id: string,
        companies: AcitveProposalCompany[],
        command: ActiveProposalStatus,
        reason?: ReafuseReason | null,
        date?: string | null,
        missionId?: string | null
    ) => {
        loading.value = true;

        const timestamp = Date.now();

        const formatedCompanies = companies.map((company) => ({
            companyId: company._id,
            status: command,
            contact: company.contact,
            date: date ? moment(date, "DD MMM. YYYY", "fr").valueOf() : timestamp,
            missionId: missionId || null,
            reason: reason || null,
        }));

        const payload = {
            id,
            body: { companies: formatedCompanies },
        };

        try {
            response.value = await store.dispatch("actions/editActiveProposal", payload);
            if (response.value?.ok && onSucceed) onSucceed();
        } catch (error) {
            if (onError) onError(error);
        }

        loading.value = false;
    };

    return [updateActivePropposal, { loading: loading.value, response: response.value }];
};

export const getDate = (date: Timestamp | number | null | undefined) => {
    return moment(date).format("D MMM YY");
};
