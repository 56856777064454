// Colors
export const black = "#161616";
export const lightBlack = "#1B1B1B";
export const white = "#fff";
export const red = "#cc0033";
export const orange = "#FFA630";
export const green = "#6AB547";
export const lightGrey4 = "#F2F2F2";
export const lightGrey3 = "#FAFAFA";
export const lightGrey2 = "#F5F6FA";
export const lightGrey = "#E4E4E4";
export const grey2 = "#D5D8E2";
export const grey = "#98999D";
export const darkGrey = "#7A899E";
export const darkGrey2 = "#5D697E";

export const darkBlue = "#130D6F";
export const purple = "#5E72EB";
export const lightPurple = "#EFF1FD";
export const teal = "#4ADEDE";
export const paleBlue = "#5A87B8";
export const lightGreen = "#A0E29A";
export const lightRed = "#FC8686";
export const lightPurple2 = "#DCC8FC";
export const lightOrange = "#F9C28C";
export const lightBlue = "#B9D8F9";
export const lightBlue2 = "#EBF4FD";
export const lightBlue3 = "#F8F9FF";
export const purpleGradient = "linear-gradient(263.85deg, #B9D8F9 -11.05%, #DCC8FC 100%)";

// DESIGN SYSTEM COLORS

// NEUTRAL
export const neutral0 = "#FFFFFF";
export const neutral50 = "#F6F7F9";
export const neutral100 = "#EFF2F5";
export const neutral200 = "#DBDFE6";
export const neutral300 = "#ACB1C3";
export const neutral400 = "#9297AA";
export const neutral500 = "#636778";
export const neutral600 = "#494E61";
export const neutral700 = "#323848";
export const neutral800 = "#1A1F30";
export const neutral900 = "#00061A";

// PRIMARY
export const primary100 = "#EFF1FD";
export const primary200 = "#E7EAFC";
export const primary300 = "#CDD3F9";
export const primary400 = "#5E72EB";
export const primary500 = "#5567D4";
export const primary600 = "#4B5BBC";
export const primary700 = "#4756B0";
export const primary800 = "#38448D";
export const primary900 = "#2A336A";

// AZUR
export const azur100 = "#E1FEFD";
export const azur200 = "#C4FAFD";
export const azur300 = "#A6EFF9";
export const azur400 = "#8EE1F4";
export const azur500 = "#69CBED";
export const azur600 = "#4CA2CB";
export const azur700 = "#347BAA";
export const azur800 = "#215889";
export const azur900 = "#143F71";

// BLUE
export const blue50 = "#F5F7FF";
export const blue100 = "#EBEFFF";
export const blue200 = "#D6DFFF";
export const blue300 = "#C2D0FF";
export const blue400 = "#99B0FF";
export const blue500 = "#6689FF";
export const blue600 = "#3D69FF";
export const blue700 = "#2051F8";
export const blue800 = "#042AAF";

// PURPLE
export const purple100 = "#F4EDFD";
export const purple200 = "#E9DAFB";
export const purple300 = "#D8BFF8";
export const purple400 = "#C19AF3";
export const purple500 = "#A268EE";
export const purple600 = "#883EE9";
export const purple700 = "#7520E6";
export const purple800 = "#46108E";

// YELLOW
export const yellow50 = "#FFFCF5";
export const yellow100 = "#FFF9EB";
export const yellow200 = "#FEF3D7";
export const yellow300 = "#FDEAB9";
export const yellow400 = "#FDE4A5";
export const yellow500 = "#FCD87D";
export const yellow600 = "#FCCF5F";
export const yellow700 = "#FBC43C";
export const yellow800 = "#D79B04";

// GREEN
export const green50 = "#ECFEF5";
export const green100 = "#ECFEF5";
export const green200 = "#DAFBEB";
export const green300 = "#93F1C4";
export const green400 = "#4BE79C";
export const green500 = "#10DA79";
export const green600 = "#00C767";
export const green700 = "#00A355";
export const green800 = "#00703A";

// RED
export const red50 = "#FEF6F8";
export const red100 = "#FDEDF0";
export const red200 = "#FBDAE1";
export const red300 = "#F8BFCA";
export const red400 = "#F49AAB";
export const red500 = "#EE637D";
export const red600 = "#E93A5B";
export const red700 = "#E61940";
export const red800 = "#8A0F27";

// ORANGE
export const orange50 = "#FFF8F5";
export const orange100 = "#FFF1EB";
export const orange200 = "#FFE2D6";
export const orange300 = "#FFCDB8";
export const orange400 = "#FFB08F";
export const orange500 = "#FF8552";
export const orange600 = "#FF6829";
export const orange700 = "#FF5006";
export const orange800 = "#9E2F00";

// SUCCESS
export const success100 = "#F4FDED";
export const success200 = "#E6FCDB";
export const success300 = "#D2F6C7";
export const success400 = "#BEEDB4";
export const success500 = "#A0E29A";
export const success600 = "#70C270";
export const success700 = "#4DA254";
export const success800 = "#4DA254";
export const success900 = "#1D6C30";

// WARNING
export const warning100 = "#FEF7E8";
export const warning200 = "#FEEED1";
export const warning300 = "#FDE2BA";
export const warning400 = "#FBD5A8";
export const warning500 = "#F9C28C";
export const warning600 = "#F9C28C";
export const warning700 = "#B37146";
export const warning800 = "#904E2C";
export const warning900 = "#77361A";

// ERROR
export const error100 = "#FEE2E2";
export const error200 = "#FEDBCE";
export const error300 = "#FEC3B6";
export const error400 = "#FDABA4";
export const error500 = "#FC8686";
export const error600 = "#D8616C";
export const error700 = "#B54357";
export const error800 = "#922A45";
export const error900 = "#78193A";

// VIOLET
export const violet100 = "#FAF4FE";
export const violet200 = "#F4E9FE";
export const violet300 = "#EDDEFE";
export const violet400 = "#E6D6FD";
export const violet500 = "#DCC8FC";
export const violet600 = "#DCC8FC";
export const violet700 = "#7D64B5";
export const violet800 = "#553F92";
export const violet900 = "#392678";

// ALIAS
export const primaryDark = blue800;
export const primaryBase = blue700;
export const primaryLight = blue200;
export const primaryLighter = blue100;
export const bgStrong900 = neutral900;
export const bgSurface700 = neutral700;
export const bgSoft200 = neutral200;
export const bgWeak100 = neutral100;
export const bgWhite0 = neutral0;
export const strokeStrong900 = neutral900;
export const strokeSub300 = neutral300;
export const strokeSoft200 = neutral200;
export const strokeDisabled100 = neutral100;
export const strokeWhite0 = neutral0;
export const textMain900 = neutral900;
export const textSub600 = neutral600;
export const textSoft400 = neutral400;
export const textDisabled300 = neutral300;
export const textWhite0 = neutral0;
export const iconStrong900 = neutral900;
export const iconSub500 = neutral500;
export const iconSoft400 = neutral400;
export const iconDisabled300 = neutral300;
export const iconWhite0 = neutral0;
export const blueDark = blue800;
export const blueBase = blue700;
export const blueLight = blue200;
export const blueLighter = blue100;
export const purpleDark = purple800;
export const purpleBase = purple700;
export const purpleLight = purple200;
export const purpleLighter = purple100;
export const redDark = red800;
export const redBase = red700;
export const redLight = red200;
export const redLighter = red100;
export const orangeDark = orange800;
export const orangeBase = orange700;
export const orangeLight = orange200;
